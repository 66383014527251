import Glide from '@glidejs/glide';

export default function (dom, opts, htmlcomponent) {
	let options = {
		type: 'carousel',
		perView: 1,
		gap: 0,
		keyboard: false,
		autoplay: false,
		breakpoints: {
			1279: {
				perView: 1,
			},
			767: {
				perView: 1,
				autoplay: 5000,
				hoverpause: true
			},
		},
		swipeThreshold: 80,
		dragThreshold: 120,
		perTouch: 0,
		touchRatio: 0.5,
		touchAngle: 45,
		rewind: true,
		...opts
	};

  let glide = new Glide(dom.querySelector('.glide'), options).mount();

  htmlcomponent.onElementDestroy(dom, () => {
    try {
      glide.destroy();
      glide = null;
    }
    catch(err) {
      // nothing to do
    }
  });
}
