import htmlComponent from 'htmlcomponent';

// write cookie
export const writeCookie = (cookieName, value, expiration = 365) => {
	document.cookie = `${cookieName}=${value}; expires=${(new Date(Date.now() + (1000 * 60 * 60 * 24 * expiration))).toString()}; Secure`;
};

// read cookie
export const readCookie = (cookieName) => {
	return (document.cookie.match(new RegExp(`^(?:.*;)?\\s*${cookieName}\\s*=\\s*([^;]+)(?:.*)?$`))||[,null])[1];
};

export default function(dom, opts) {
	const options = {
		cookieName: 'cookie-consent',
		expiration: 365,
		eventName: 'cookie:consent',
		submitSelector: '[data-consent-submit]',
		optionSelector: '[data-consent-option]',
		selectionSelector: '[data-consent-selection]',
		doneClass: 'cookie-consent--done',
		reloadOnChange: false,
		...opts,
	};

	// check if cookie is already set
	let cookieSet = !!readCookie(options.cookieName);

	// add the done class to the dom element if the cookie is set
	dom.classList.toggle(options.doneClass, cookieSet);

	dom.addEventListener('click', e => {
		// try to match the submit selector
		if (e.target.matches(options.submitSelector) || e.target.closest(options.submitSelector)) {
			// get the actuall element matching the selector
			const button = e.target.matches(options.submitSelector) ? e.target : e.target.closest(options.submitSelector)
			// find an element containing matching the selection selector, but not the submit selector
			const selectionElement = dom.querySelector(`${options.selectionSelector}:not(${options.submitSelector})`);
			// find any elements matching the option selector
			const optionElements = Array.from(dom.querySelectorAll(options.optionSelector));
			// get selection value
			const selection =
				button?.dataset.consentSelection ?? // data attribute on the submit element
				selectionElement?.dataset.consentSelection ?? // data attribute on the selection element
				selectionElement?.value ?? // value of the selection element, i.e. a hidden input
				optionElements.reduce((options, option) => {
					if (option.checked) {
						options.push(option.value)
					}
					return options;
				}, []).join(','); // map the option elements (assumed to be checkboxes)
			// write the cookie (must be before htmlComponent.send)
			writeCookie(options.cookieName, selection, options.expiration);
			// send the event through the htmlComponent event bus
			htmlComponent.send(options.eventName, selection);
			// add the done class to the dom element
			dom.classList.add(options.doneClass);

			// if cookie is already set and reloadOnChange is true, reload window
			if (cookieSet && options.reloadOnChange) {
				location.reload();
			}

			// set cookieSet
			cookieSet = true;
		}
	});
}
