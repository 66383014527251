import Glide from '@glidejs/glide';

export default function (dom, opts, htmlcomponent) {
    let options = {
        imageContainerSel: '.image_container',
        bulletsSel: '.glide__bullets',
        cloneCls: 'glide__slide--clone',
        singleBulletSel: '.glide__bullet',
        singleBulletCls: 'glide__bullet',
        ...opts
    };

    let customOptions = {
        type: 'carousel',
        perView: 1,
        gap: 0,
        keyboard: false,
        autoplay: false,
        breakpoints: {
            1279: {
                perView: 1,
            },
            767: {
                perView: 1,
                autoplay: 5000,
                hoverpause: true
            },
        },
        swipeThreshold: 80,
        dragThreshold: 120,
        perTouch: 0,
        touchRatio: 0.5,
        touchAngle: 45,
        rewind: true,
    };


    const imageContainers = Array.from(dom.querySelectorAll(options.imageContainerSel));
    const bulletsContainer = dom.querySelector(options.bulletsSel);
    let glide = new Glide(dom.querySelector('.glide'), customOptions).mount();


    if (glide) {
        imageContainers.forEach((container, index) => {
            let clonedImages = container.classList.contains(options.cloneCls)

            if (!clonedImages) {
                let bulletButton = document.createElement('button');
                bulletButton.classList.add(options.singleBulletCls);
                bulletButton.setAttribute("data-glide-dir", `=${index}`);
                bulletsContainer.appendChild(bulletButton);

                bulletButton.addEventListener('click', () => {
                    glide.go(`=${index}`)
                })
            }
        })
    }


    htmlcomponent.onElementDestroy(dom, () => {
        try {
            glide.destroy();
            glide = null;
        } catch (err) {
            // nothing to do
        }
    });
}
