import htmlComponent from 'htmlcomponent';
import { readCookie } from './cookieconsent';

export default function(dom, opts) {
	const options = {
		cookieName: 'cookie-consent',
		eventName: 'cookie:consent',
		category: 'marketing',
		delay: false,
		...opts
	};

	// create script element
	const script = document.createElement('script');
	if (options.src) {
		script.src = options.src;
	}
	else {
		script.innerHTML = dom.innerHTML;
	}
	if (options.delay) {
		script.async = true;
	}
	// create placeholder for script
	const placeholder = document.createComment('');
	dom.replaceWith(placeholder);

	// set default loaede state
	let loaded = false;

	// add script element to the DOM
	const addScript = () => {
		placeholder.replaceWith(script);
	};

	// read the cookie to check if the script can be loaded
	const checkCookie = () => {
		const cookie = readCookie(options.cookieName);
		const categories = cookie ? cookie.split(/\s?,\s?/) : []

		if (!loaded && cookie && categories.includes(options.category)) {
			if (document.readyState === 'complete' || !options.delay) {
				addScript();
			} else if (options.delay) {
				window.addEventListener('load', addScript);
			}
			loaded = true;
		}
	};

	// listen for the event of the cookie consent script
	htmlComponent.listen(options.eventName, () => {
		checkCookie();
	});

	// initially check the cookie
	checkCookie();
}
