export default function(dom, opts) {
	const options = {
		dialogName: 'dialog',
		openSelector: '[data-dialog-open]',
		closeSelector: '[data-dialog-close]',
		toggleSelector: '[data-dialog-toggle]',
		...opts,
	};

	const openDialog = () => {
		dom.showModal();
	};
	const closeDialog = () => {
		dom.close();
	};
	const toggleDialog = () => {
		if (dom.open) {
			closeDialog();
		} else {
			openDialog();
		}
	};

	document.addEventListener('click', e => {
		if (e.target.matches(options.openSelector) || e.target.closest(options.openSelector)) {
			const button = e.target.matches(options.openSelector) ? e.target : e.target.closest(options.openSelector);
			const target = button.dataset.dialogOpen;

			if (target === options.dialogName) {
				openDialog();
			}
		}
		else if (e.target.matches(options.closeSelector) || e.target.closest(options.closeSelector)) {
			const button = e.target.matches(options.closeSelector) ? e.target : e.target.closest(options.closeSelector);
			const target = button.dataset.dialogClose;

			if (target === options.dialogName) {
				closeDialog();
			}
		}
		else if (e.target.matches(options.toggleSelector) || e.target.closest(options.toggleSelector)) {
			const button = e.target.matches(options.toggleSelector) ? e.target : e.target.closest(options.toggleSelector);
			const target = button.dataset.dialogToggle;

			if (target === options.dialogName) {
				toggleDialog();
			}
		}
	})
}
