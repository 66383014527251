import {isMobile, supportsPassive} from '@/utils/features';

export default function (dom, opts) {
    let options = {
        baseClass: 'features',
        showClass: 'features__single--blend-in',
        ...opts
    };

    const texts = Array.from(dom.querySelectorAll(`.${options.baseClass}__single-content`));
    const rightSideFiles = Array.from(dom.querySelectorAll(`.${options.baseClass}__single-file-content`))
    const fileType = Array.from(dom.querySelectorAll(`.${options.baseClass}__single-file-content :is(img, video)`));
    const thresholdTop = 0.15;
    const mobileThreshold = 0.5;
    let currentText;

    const updatePosition = async () => {
        let index;

        if (!isMobile()) {
            currentText = texts[0];
            for (let text of texts) {
                const rect = text.getBoundingClientRect();

                if (rect.top <= (window.innerHeight * thresholdTop)) {
                    currentText = text;
                }
            }
            index = texts.indexOf(currentText);
        } else {
            currentText = rightSideFiles[0];
            for (let file of rightSideFiles) {
                const rect = file.getBoundingClientRect();

                if (rect.top <= (window.innerHeight * mobileThreshold)) {
                    currentText = file;
                }
            }
            index = rightSideFiles.indexOf(currentText);
        }

        let splitColors = options.testColor.split(' ')
        dom.style.backgroundColor = `#${splitColors[index]}`;

        rightSideFiles.forEach(file => file.classList.remove(options.showClass));
        fileType.forEach(type => {
            try {
                type.pause();
            } catch {

            }
        });

        rightSideFiles[index]?.classList.add(options.showClass);

        try {
            await fileType[index]?.play();
        } catch {
            // do nada
        }
    };

    window.addEventListener('scroll', updatePosition, supportsPassive ? {passive: false} : null);
    updatePosition()

    htmlcomponent.onElementDestroy(dom, () => {
        try {
            window.removeEventListener('scroll', updatePosition, supportsPassive ? {passive: false} : null);
        } catch (err) {
            // nothing to do
        }
    });
}
