import BeerSlider from 'beerslider';

export default function(dom, opts, htmlcomponent) {
	let options = {
		sliderSel: "#ba-slider",
		...opts
	};

	const selectSlider = dom.querySelector(options.sliderSel);
	let slider = new BeerSlider(selectSlider);

	selectSlider.addEventListener('mousedown', (e) => {
		dom.classList.add('slider-moved');
	});

	selectSlider.addEventListener('mouseup', (e) => {
		dom.classList.remove('slider-moved');
	});

  htmlcomponent.onElementDestroy(dom, () => {
    try {
      slider.destroy();
      slider = null;
    }
    catch(err) {
      // nothing to do
    }
  });
}
