import setupParallax from '@/utils/setupParallax';
import {getRandomNumber} from '@/utils/random';

export default function (dom, opts, htmlcomponent) {
    let options = {
        selector: '',
        baseMovement: 0,
        fullMovement: -50,
    };

    const columnsAll = Array.from(document.querySelectorAll('.column'));
    const columnsSlow = Array.from(document.querySelectorAll('.column__slow'));
    const columnsFast = Array.from(document.querySelectorAll('.column__fast'));

    const randomItemFactors = columnsSlow.map(() => getRandomNumber(0.5, 1));
    const randomItemFactorsSecond = columnsFast.map(() => getRandomNumber(0.5, 1));

    const floatScreens = ({factor, factorReversed}) => {

        for (let i in columnsFast) {
            columnsFast[i].style.transform = `translate3d(0, ${options.baseMovement + (factor * options.fullMovement * randomItemFactorsSecond[i])}%, 0)`;
        }
        for (let j in columnsSlow) {
            columnsSlow[j].style.transform = `translate3d(0, ${options.baseMovement + (factorReversed * options.fullMovement * randomItemFactors[j])}%, 0)`;
        }

/*        for (let k in columnsAll) {
            columnsAll[k].style.transform = `translate3d(0, ${options.baseMovement + (factor * options.fullMovement * randomItemFactors[k])}%, 0)`;
        }*/
    }

    const {removeParallax} = setupParallax(dom, floatScreens, true);

    htmlcomponent.onElementDestroy(dom, () => {
      try {
        removeParallax();
      }
      catch(err) {
        // nothing to do
      }
    });
}
