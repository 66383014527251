import Glide from '@glidejs/glide';
import {isMobile} from "@/utils/features";

export default function (dom, opts, htmlcomponent) {
    let options = {
        sliderOverlaySel: '.gallery-image-overlay',
        openOverlay: '[data-open-overlay]',
        closeOverlay: '[data-close-overlay]',
        showOverlayHtmlCls: 'gallery-item-open',
        showOverlayCls: 'gallery--open',
        ...opts
    };

    let glideOptions = {
        type: 'carousel',
        perView: 1,
        gap: 0,
        keyboard: false,
        autoplay: false,
        swipeThreshold: 80,
        dragThreshold: 120,
        perTouch: 1,
        touchRatio: 0.5,
        touchAngle: 45,
        rewind: true,
    };


    const sliderOverlay = dom.querySelector(options.sliderOverlaySel);
    const slideCountOverlay = [].slice.call(sliderOverlay.querySelectorAll('.glide__slide'), ).length;
    const overlayOpen = [].slice.call(dom.querySelectorAll(options.openOverlay), 0);
    const overlayClose = [].slice.call(dom.querySelectorAll(options.closeOverlay), 0);

    const html = document.documentElement;
    const body = document.body;
    let glideOverlay;

    const keyboardHandler = e => {
      try {
        if (dom.classList.contains(options.showOverlayCls)) {
          if (e.key === 'Escape') {
            closeOverlay();
          }
          else if (e.key === 'ArrowUp') {
            glideOverlay.go('<<');
          }
          else if (e.key === 'ArrowDown') {
            glideOverlay.go('>>');
          }
          else if (e.key === 'ArrowLeft') {
            glideOverlay.go('<');
          }
          else if (e.key === 'ArrowRight') {
            glideOverlay.go('>');
          }
        }
      }
      catch {}
    };

    const closeOverlay = () => {
      html.classList.remove(options.showOverlayHtmlCls);
      dom.classList.remove(options.showOverlayCls);
    };

    if (slideCountOverlay) {
      glideOverlay = new Glide(sliderOverlay.querySelector('.glide'), glideOptions).mount();
      overlayOpen.forEach((item, index) => {
        item.addEventListener('click', () => {
          glideOverlay.go(`=${index}`);
          html.classList.add(options.showOverlayHtmlCls);
          dom.classList.add(options.showOverlayCls);
        })
      });

      overlayClose.forEach(item => {
        item.addEventListener('click', () => {
          closeOverlay();
        })
      });

      body.addEventListener('keydown', keyboardHandler)
    }

    htmlcomponent.onElementDestroy(dom, () => {
      try {
        closeOverlay();
        body.removeEventListener('keydown', keyboardHandler)

        glideOverlay.destroy();
        glideOverlay = null;
      }
      catch(err) {
        // nothing to do
      }
    });
}
