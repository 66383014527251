import InSicht from 'insicht';
import formatNumber from '@/utils/formatNumber';

export default function(dom, opts, htmlcomponent) {
	let options = {
		speed: 100,
		format: {
			decimals: 0,
			thousand: '\'',
		},
		...opts
	};

	let stats = new InSicht({
    container: dom,
    selector: '[data-target]',
    visibleClass: 'numbers-grid__value--count',
    stagger: 100,
		threshold: 0,
		// autoReset: true, // only for dev ;)
    init: (counter) => {
			const placeholder = counter.querySelector('[data-placeholder]');
			const display = counter.querySelector('[data-display]');
			const target = +counter.dataset.target;

			placeholder.innerText = formatNumber(target, options.format);
			display.innerText = 0;
		},
    done: (counter) => {
			const target = +counter.dataset.target;
			const display = counter.querySelector('[data-display]');

			const updateCount = () => {
				const count = +counter.dataset.value;

				const inc = target / options.speed;

				if (count < target) {
					const newValue = count + inc;
					counter.dataset.value = newValue;
					display.innerText = formatNumber(Math.min(newValue, target), options.format);
					frame = requestAnimationFrame(updateCount);
				}
				else {
					cancelAnimationFrame(frame);
					display.innerText = formatNumber(target, options.format);
				}
			};
			let frame;

			counter.dataset.value = 0;

			updateCount();
		},
	});


  htmlcomponent.onElementDestroy(dom, () => {
    try {
      stats.destroy();
      stats = null;
    }
    catch(err) {
      // nothing to do
    }
  });

}
