import throttle from '@/utils/throttle';

export default function(dom, opts, htmlcomponent) {
	let options = {
		selector: '[data-marquee]',
		factor: 0.18,
		...opts
	};

	const elements = Array.from(dom.querySelectorAll(options.selector));
	const firstElement = elements[0];

	const resize = () => {
		const pxPerSecond = Math.round(window.innerWidth * options.factor);
		const speed = Math.round(firstElement.clientWidth / pxPerSecond);

		elements.forEach(element => {
			element.style.animationDuration = `${speed}s`;
		});
	};
  const throttledResize = throttle(resize);

	window.addEventListener('resize', throttledResize);
	window.addEventListener('load', resize);

	resize();

  htmlcomponent.onElementDestroy(dom, () => {
    try {
      window.removeEventListener('resize', throttledResize);
      window.removeEventListener('load', resize);
    }
    catch(err) {
      // nothing to do
    }
  });
}
