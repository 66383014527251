import autocreate from 'autocreate.js';
import Scrrroll from 'scrrroll';
import { router } from '@/handlers/router';

autocreate({
	selector: 'a[href]:not([data-hc="app/scrollto"]):not([data-hci="app/scrollto"])',
	create: function(el) {
		el.addEventListener('click', async e => {
			const href = el.href;
			let _href = el.getAttribute('href');
			const target = el.target;
			const regex = new RegExp('^' + location.origin);

			el.blur();

			if (target === '_blank') {
				return;
			}

			if (href) {
				if (e.metaKey || e.shiftKey || e.ctrlKey || e.altKey) {
					return;
				}

				if (/^(?!http.*$).*/.test(href)) {
					return;
				}

				if (href.indexOf(location.origin + location.pathname) === 0 && href.indexOf('#') >= 0) {
					_href = '#' + href.split('#').pop();
				}

				if (_href.match(/^\#.+/)) {
					const anchor = document.querySelector(_href);
					if (anchor) {
						e.preventDefault();
						await Scrrroll.to(anchor.offsetTop);
						location.hash = _href;
						return false;
					}
					return false;
				}

				if (!_href.match(/^\#/) && href.match(regex) && (!target || target === '_self')) {
					e.preventDefault();
					router.redirect(href);
					return false;
				}
			}
		});
	},
	destroy: function(el) {}
});
