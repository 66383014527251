import breakpoints from '@/utils/breakpoints';

// check if the breakpoint matches
export function breakpointMatch(breakpoint, type = 'min') {
  let size = breakpoints[breakpoint];

	if (!size) {
		return false;
	}

  if (type === 'max') {
		size = size - 0.001;
  }
  else {
    type = 'min';
  }
  return window.matchMedia(`(${type}-width: ${size}em)`).matches;
}

export default breakpointMatch;
