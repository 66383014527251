export default function (dom, opts) {
    let options = {
        targetSel: '.content',
        triggerSel: '.sticky-link__link',
        ...opts
    };

    const body = document.body;
    const trigger = dom.querySelector(options.triggerSel)
    const topTarget = body.querySelector(options.targetSel)

    function init() {
        scrollToTop();
    }

    function scrollToTop() {
        trigger.addEventListener('click', () => {
            topTarget.scrollIntoView({behavior: 'smooth'})
        })
    }


    init()

}
