import { supportsPassive } from '@/utils/features';

export const setupParallax = (element, callback = () => {}, fullscreen = false) => {
	if (!(element instanceof HTMLElement)) {
		return;
	}

	let rect;
	let top;
	let bottom;
	let range;
	let position;
	let factor;
	let factorReversed;

	const updateParallax = () => {
		if (fullscreen) {
			rect = element.getBoundingClientRect();
			top = rect.top;
			range = element.clientHeight - window.innerHeight;
			position = Math.min(Math.abs(Math.min(top, 0)), range);
			factor = Math.min(Math.max(position / range, 0), 1);
			factorReversed = 1 - factor;
		}
		else {
			rect = element.getBoundingClientRect();
			position = rect.bottom;
			top = 0;
			bottom = window.innerHeight + rect.height;
			range = bottom;
			factorReversed = Math.min(Math.max(position / range, 0), 1);
			factor = 1 - factorReversed;
		}

		requestAnimationFrame(() => {
			// element.style.setProperty('--factor', factor);
			// element.style.setProperty('--factor-reversed', factorReversed);
			callback({factor, factorReversed});
		});
	};

	const addParallax = () => {
		window.addEventListener('scroll', updateParallax, supportsPassive ? {passive: false} : null);
	};

	const removeParallax = () => {
		window.removeEventListener('scroll', updateParallax, supportsPassive ? {passive: false} : null);
	};

	addParallax();
	updateParallax();

	return {
		addParallax,
		updateParallax,
		removeParallax,
	};
};

export default setupParallax;
