import {isIos, isIos9, isIE, isEdge} from '@/utils/features';

if (isIos) {
	document.documentElement.classList.add('is-ios');
}

if (isIos9) {
	document.documentElement.classList.add('is-ios9');
}

if (isIE) {
	document.documentElement.classList.add('is-ie');
}
