export default function (dom, opts) {
    let options = {
        ...opts
    }

    const html = document.documentElement;

    dom.addEventListener('click', async e => {

        onToggle();

    });


    function onToggle() {
        if (html.classList.contains('show--nav')) {
            onClose();
        } else {
            onOpen();
        }
    }

    function onClose() {
        html.classList.remove('show--nav');
    }

    function onOpen() {
        html.classList.add('show--nav');
    }
}
