import htmlComponent from "htmlcomponent";

export default function (dom, opts) {
    let options = {
        linkSel: 'a',
        ...opts
    };

    const findLink = dom.querySelector(options.linkSel);
    let lastUrl = localStorage.getItem('last-filter');

    function init() {
        historyLink();
    }

    function historyLink() {
        if (lastUrl) {
            findLink.href = lastUrl;
        }
    }

    init()

}
