export default function (dom, opts) {
    let options = {
        ...opts
    }

    const accelerator = 0.02;
    let headlines = Array.from(dom.querySelectorAll('.animate-letters'));

    headlines.forEach(headline => {
      // find highlighted words
      let highlightedAreas = Array.from(headline.querySelectorAll('.highlight-text'));
      let highlightedWords = [];
      highlightedAreas.forEach(area => highlightedWords.push(area.textContent));

      let words = headline.textContent.match(/\S+/g);
      // console.info(words);
      let newContent = '';
      let h1 = headline;
      let charpos = 0;
      let filteredWord;

      // Go through all characters of the sentence
      words.forEach((word, i) => {
          filteredWord = word.replace(' ', '');
          newContent += '<span class="word">';

          if (highlightedWords.includes(filteredWord)) {
            newContent += '<span class="highlight-text">'
          }

          for (i = 0; i < filteredWord.length; i++) {
            charpos = h1.textContent.indexOf(filteredWord) + i + 2;
            newContent += '<span class="character" style="transition-delay:' + (charpos * accelerator) + 's">' + filteredWord.substr(i,1) + '</span>';
          }
          newContent += '</span>';

          if (highlightedWords.includes(filteredWord)) {
            newContent += '</span>'
          }
      });

      h1.innerHTML = newContent;
    });
}
