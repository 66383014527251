import htmlComponent from 'htmlcomponent'

export default function(dom, opts) {
    let options = {
        ...opts
    };

    const desktopWidth = 1024;

    const baseClass = 'collapsible';

    const trigger = dom.querySelector(`.${baseClass}__trigger`);
    const content = dom.querySelector(`.${baseClass}__content`);
    const baseClassSelector = dom.querySelector(`.${baseClass}`);
    const contentTransition = e => {
        content.removeEventListener('transitionend', contentTransition);
        content.style.maxHeight = '';
    };

    if (!dom.classList.contains(`${baseClass}--collapsed`) && window.innerWidth < desktopWidth) {
        dom.classList.add(`${baseClass}--collapsed`);
    }

    trigger && content && trigger.addEventListener('click', e => {
        e.preventDefault();

        content.removeEventListener('transitionend', contentTransition);
        content.addEventListener('transitionend', contentTransition);

        content.style.maxHeight = `${content.scrollHeight}px`;

        setTimeout(() => {
            dom.classList.toggle(`${baseClass}--collapsed`);
        }, 25);

        return false;
    });
}
