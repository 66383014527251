import htmlComponent from "htmlcomponent";

export default function (dom, opts) {
    let options = {
        activeCls: 'active',
        activeSel: '[data-select]',
        ...opts
    };

    const singleElement = Array.from(dom.querySelectorAll(options.activeSel));
    const redMarker = dom.querySelector('.red-active-dot');

    function init() {
        showActive();
    }

    function showActive() {
        singleElement.forEach(el => {
            el.addEventListener('click', () => {

                singleElement.forEach(item => {
                    item.classList.remove(options.activeCls);
                });

                el.classList.add(options.activeCls);
                redActiveMarker(el);
            })
            redActiveMarker(el);
        })
    }

    function redActiveMarker (x) {
        if (redMarker) {
            if (x.classList.contains('active')) {
                redMarker.classList.add(options.activeCls);
            } else {
                redMarker.classList.remove(options.activeCls);
            }
        }
    }

    init()

}
