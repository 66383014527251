import InSicht from 'insicht';

export default function (dom, opts, htmlcomponent) {
    let options = {
        selector: '.hero__mask',
        class: 'hero__mask--move'
    };


    let insicht = new InSicht({
        container: document.documentElement,
        selector: `.${dom.className.split(' ').join('.')}`,
        visibleClass: 'hero--animate',
        stagger: 200,
        threshold: 0,
        autoRefresh: false,
        autoReset: true,
        init: (item, instance) => {},
        done: (item, instance) => {},
    });

    let insichtPhone = new InSicht({
        container: document.documentElement,
        selector: `.${dom.className.split(' ').join('.')}`,
        visibleClass: 'hero--animate-phone',
        stagger: 200,
        threshold: 0.55,
        autoRefresh: false,
        autoReset: false,
        init: (item, instance) => {},
        done: (item, instance) => {},
    });

    htmlcomponent.onElementDestroy(dom, () => {
      try {
        insicht.destroy();
        insichtPhone.destroy();
        insicht = null;
        insichtPhone = null;
      }
      catch(err) {
        // nothing to do
      }
    });
}

