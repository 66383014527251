export default function (dom, opts, htmlcomponent) {
    let options = {
        vidOverlayCls: 'video-in-overlay',
        cinematicCls: 'cinematic-ratio',
        videoIdentifier: '',
        ...opts
    };

    const body = document.body


    const poster = dom.querySelector('.video__poster');
    const vidContainer = document.createElement('div')
    const vidOverlayContainer = document.createElement('div')
    vidOverlayContainer.classList.add('video-overlay');

    const tmpl = dom.querySelector('script[type="text/template"]');
    const videoTeaser = document.body.querySelector('.hero--w-video');
    const showcasePlayButton = document.body.querySelector(`[data-play-video="${options.videoIdentifier}"]`);


    if (options.cinematicRatio === "1" && !dom.classList.contains(options.cinematicCls)) {
        dom.classList.add(options.cinematicCls);
    }

    if (!tmpl) {
        return false;
    } else if (dom.classList.contains(options.vidOverlayCls)) {

        playOverlayVideo();

      if (showcasePlayButton) {
          playFromShowcase();
      }
    } else {
        playVideo();
    }

    function playVideo() {

        if (options.cinematicRatio === "1") {
            dom.classList.add('cinematic-ratio');
        }

        vidContainer.innerHTML = tmpl.innerHTML;
        tmpl.parentNode.removeChild(tmpl);

        poster.addEventListener('click', e => {
            e.preventDefault();

            poster.parentNode.replaceChild(vidContainer, poster);


            try {
                vidContainer.querySelector('video').play();

            } catch (err) {

            }

            if (videoTeaser) {
                videoTeaser.classList.add('play-video')
            }

        });
    }

    function playFromShowcase() {
        showcasePlayButton.addEventListener('click', () => {
          document.body.appendChild(vidOverlayContainer);
        })

    }

    function playOverlayVideo() {
        const videoContainer = document.createElement('div');
        videoContainer.classList.add('video-overlay__container');
        const videoFigure = document.createElement('figure');
        videoFigure.classList.add('video-overlay__video');
        const videoWrapper = document.createElement('div');
        videoWrapper.classList.add('video-overlay__wrapper', 'video-overlay__wrapper--full-screen');


        videoFigure.appendChild(videoWrapper);
        videoContainer.appendChild(videoFigure);
        vidOverlayContainer.appendChild(videoContainer);

        videoWrapper.innerHTML = tmpl.innerHTML;
        tmpl.parentNode.removeChild(tmpl);

        const createCloseBtn = document.createElement('button');
        createCloseBtn.classList.add('video-overlay__close');
        createCloseBtn.innerHTML = '<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M30.45 51.9C42.2965 51.9 51.9 42.2965 51.9 30.45C51.9 18.6035 42.2965 9 30.45 9C18.6035 9 9 18.6035 9 30.45C9 42.2965 18.6035 51.9 30.45 51.9Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
            '<path d="M21.65 21.65L39.25 39.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
            '<path d="M39.25 21.65L21.65 39.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
            '</svg>\n';

        createCloseBtn.addEventListener('click', e => {
            body.removeChild(vidOverlayContainer);
            document.documentElement.classList.remove('overlay-active');
        });
        // vidOverlayContainer.appendChild(createDiv);
        vidOverlayContainer.prepend(createCloseBtn);


        const videoElement = vidOverlayContainer.querySelector('video');


        poster.addEventListener('click', e => {
            e.preventDefault();
            if (dom.classList.contains('video-iframe')) {

            } else {
                videoElement.currentTime = 0;
            }

            body.append(vidOverlayContainer);
            document.documentElement.classList.add('overlay-active');

            if (options.cinematicRatio === "1") {
                videoWrapper.classList.add('video-overlay__wrapper--cinematic');
            }

            try {
                vidOverlayContainer.querySelector('video').play();
            } catch (err) {
            }
        });
    }

    htmlcomponent.onElementDestroy(dom, () => {
      try {
        body.removeChild(vidOverlayContainer);
      }
      catch(err) {
        // nothing to do
      }
    });

}
