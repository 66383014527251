
import PageLoader from '@/lib/PageLoader';

export const loader = new PageLoader();
const html = document.documentElement;

window.addEventListener('pageshow', e => {
  if (e.persisted) {
    window.location.reload();
  }
});

document.addEventListener('DOMContentLoaded', async () => {
	if (document.readyState === 'complete') {
		loader.hide();
	}
});

window.addEventListener('load', () => {
  loader.hide();
});

export default loader;
