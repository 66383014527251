import {isIos, isAndroid} from '@/utils/features';

if (isIos || isAndroid) {
  const handler = () => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  isIos && document.documentElement.classList.add('is-ios');
  isAndroid && document.documentElement.classList.add('is-android');
  handler();

  // Watch for orientation changes.
  // Use media query matching, beacuse Safari doesn't support the screen orientation API (yet)
  const mq = window.matchMedia('(orientation: portrait)');
  mq.addEventListener('change', handler);
}
