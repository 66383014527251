const html = document.documentElement;
const scrollDelta = 5;
const scrollThreshold = 50;
const scrollDeepThreshold = 200;
const scrollDeeperThreshold = 500;
const scrolledClass = 'page--scrolled';
const scrolledUpClass = 'page--scrolled-up';
const scrolledDeepClass = 'page--scrolled-deep';
const scrolledDeeperClass = 'page--scrolled-deeper';
const scrolledFullheightClass = 'page--scrolled-fullheight';
const scrolledMaxClass = 'page--scrolled-max';

let lastBodyTop = window.pageYOffset;
let scrollProgress = 0;
let lastScrollTop = 0;

function scrollHandler() {
	const bodyTop = document.body.getBoundingClientRect().top;
	const scrollTop = window.pageYOffset;
	const scrollHeight = html.scrollHeight - html.clientHeight;
	scrollProgress = Math.min(Math.max(((scrollTop / scrollHeight) * 100), 0), 100);

	html.style.setProperty('--scrolled', scrollProgress);
	window.scrollPercent = scrollProgress;

	if (Math.abs(lastScrollTop - scrollTop) <= scrollDelta) {
		return;
	}

	if (scrollTop >= scrollThreshold) {
		html.classList.add(scrolledClass);

		if (scrollTop >= scrollDeepThreshold) {
			html.classList.add(scrolledDeepClass);
		}
		else {
			html.classList.remove(scrolledDeepClass);
		}

		if (scrollTop >= scrollDeeperThreshold) {
			html.classList.add(scrolledDeeperClass);
		}
		else {
			html.classList.remove(scrolledDeeperClass);
		}

		if (scrollTop >= window.innerHeight) {
			html.classList.add(scrolledFullheightClass);
		}
		else {
			html.classList.remove(scrolledFullheightClass);
		}

		if (bodyTop > lastBodyTop) {
			html.classList.add(scrolledUpClass);
		}
		else {
			html.classList.remove(scrolledUpClass);
		}

		if (scrollProgress === 100) {
			html.classList.add(scrolledMaxClass);
		}
		else {
			html.classList.remove(scrolledMaxClass);
		}
	}
	else {
		html.classList.remove(scrolledClass);
		html.classList.remove(scrolledUpClass);
		html.classList.remove(scrolledDeepClass);
		html.classList.remove(scrolledDeeperClass);
		html.classList.remove(scrolledFullheightClass);
		html.classList.remove(scrolledMaxClass);
	}

	lastBodyTop = bodyTop;
	lastScrollTop = scrollTop;
}

window.addEventListener('load', scrollHandler);
window.addEventListener('scroll', scrollHandler);
window.addEventListener('resize', scrollHandler);
