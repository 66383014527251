import Routrrr from 'routrrr';
import get from '@/lib/get';
import loader from '@/handlers/loader';

export const router = new Routrrr();

const mainContent = document.querySelector('main.content');
const navLinks = Array.from(document.querySelectorAll('.nav-main__nav-item-link'));

let p = Promise.resolve();
let initialized = false;

router
	.use(req => {
		if (initialized) {
      p = p.then(async () => {
        const request = new Request(req.pathname, {
          headers: new Headers({
            accept: 'text/html'
					})
				});

        document.documentElement.classList.remove('show--nav');
				return Promise.all([
          loader.show(),
					get(request)
				]).then(([, data]) => {
          return new Promise(async resolve => {
            const parser = new DOMParser();
						const doc = parser.parseFromString(data, 'text/html');
            const body = doc.querySelector('body');
						const main = doc.querySelector('main.content');

						if (main) {
              document.title = doc.title;
              document.body.className = body.className;
							mainContent.innerHTML = main.innerHTML;
							window.scrollTo(0,0);
						}

						for (let link of navLinks) {
							if (link.href === req.href) {
								link.classList.add('active');
							}
							else {
								link.classList.remove('active');
							}
						}

						await loader.hide();
						resolve();
					});
				}).catch((...args) => console.error(args));
			});
		}
	})
	.init();

window.addEventListener('load', () => {
	initialized = true;
});
