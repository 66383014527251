export default function (dom, opts) {
    let options = {
        hideCls: 'remove-filter-type',
        itemSel: '[data-values]',
        singleFilterSel: '.showcases-filter__type',
        singleFilterInputSel: '.showcases-filter__input',

        ...opts
    };

    const filterSingle = Array.from(dom.querySelectorAll(options.singleFilterSel));
    const showcaseItem = Array.from(dom.querySelectorAll(options.itemSel));
    const nothingFound = dom.querySelector('.nothing-found');

    let filterValue = "";

    const singleElement = Array.from(dom.querySelectorAll('[data-select]'));


    function init() {
        // removeHash();
        filterRetailer();
    }

    function filterRetailer() {
        // cleans key's value on reload and initial entry
        /*localStorage.setItem('last-filter', '');*/

        /* window.addEventListener('load', () => removeHash()) */

        filterSingle.forEach((filter, index) => {
            filter.addEventListener('click', () => {
                applyFilter(filter.querySelector(options.singleFilterInputSel).value)

                if (index !== 0) {
                    // set current active filter as hash in url
                    window.location.hash = `filter=${filter.querySelector(options.singleFilterInputSel).value}`;
                    registerPath();
                }

                if (index === 0) {
                    window.location.hash = "filter=alle";
                }
            })

            // remove foreign characters
            let decodeUrl = decodeURIComponent(window.location.hash).replace(/\s/g, ' ')
            // remove hash from url
            let replaceHash = decodeUrl.replace("#filter=", '')

            // if url's hash and filter value match activate filter
            if (replaceHash === filter.querySelector(options.singleFilterInputSel).value) {
                applyFilter(filter.querySelector(options.singleFilterInputSel).value)

                singleElement.forEach(el => {
                    // if url hash and filter names match add active status
                    if (replaceHash === el.outerText.toLowerCase()) {
                        el.classList.add('active');
                    }
                })
            }
        });
    }
/*
    function removeHash() {
        history.pushState("", document.title, window.location.pathname
            + window.location.search);
    }*/

    function registerPath() {
        //   save current location to session storage
        return localStorage.setItem('last-filter', window.location)
    }

    function applyFilter(value) {
        let delay = 0;
        filterValue = value;
        showcaseItem.forEach(filter => {
            // create array with filterable values - data-value
            const values = filter.dataset.values.split(/,\s*/);
            // search for current filter value
            const active = filterValue ? values.includes(filterValue) : true;
            filter.classList.add(options.hideCls);

            if (active) {
                setTimeout(() => {
                    filter.style.animationDelay = `${delay}ms`;
                    filter.classList.remove(options.hideCls);
                    delay += 100;
                    nothingFound.classList.remove('nothing-found--show');
                }, 0)
            } else {
                nothingFound.classList.add('nothing-found--show');
            }

        })
    }

    init()

}
