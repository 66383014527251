import * as Sentry from '@sentry/browser'

export default function (dom, opts) {
  let options = {
    ...opts
  }
  if (opts.enable) {
    Sentry.init({ dsn: opts.dsn })
    Sentry.configureScope((scope) => {
      scope.setExtra('githash', options.githash)
    })
  } else {
    console.warn('sentry is disabled')
  }
}
