export default function (dom, opts, htmlcomponent) {
  let options = {
    sum: 0,
    timeout: 5000,
    ...opts
  };

  let parent = dom.closest('.widget-captcha');
  let grandParent = parent.parentNode;

  if (grandParent.matches('fieldset') && grandParent.children.length === 1) {
    parent = grandParent;
  }

  dom.required = false;
  parent.style.display = 'none';

  setTimeout(() => {
    dom.value = options.sum;
  }, options.timeout);
}
